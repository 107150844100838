.watermark-animation {
    animation: animate-around 40s linear infinite alternate;
}

@keyframes animate-around {
    0% {
        top: 16px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    25% {
        top: 50%;
        left: 16px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    50% {
        top: calc(100% - 108px);
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    75% {
        top: 50%;
        left: calc(100% - 336px);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    to {
        top: 16px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
