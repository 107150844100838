.tooltip-arrow-wrapper {
    position: absolute;
    width: 10px;
    height: 10px;
}

.tooltip-arrow {
    content: " ";
    border: 1px solid rgba(0,0,0,0.05);
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    width: 100%;
    height: 100%;
  }


div[data-popper-placement="left"] .tooltip-arrow-wrapper {
    right: -5px;
}

div[data-popper-placement="left"] .tooltip-arrow {
    border-left: 0;
    border-bottom: 0;
    border-top-right-radius: 2px;
}


div[data-popper-placement="right"] .tooltip-arrow-wrapper {
    left: -5px;
}

div[data-popper-placement="right"] .tooltip-arrow {
    border-right: 0;
    border-top: 0;
    border-bottom-left-radius: 2px;
}

div[data-popper-placement="top"] .tooltip-arrow-wrapper {
    bottom: -5px;
}

div[data-popper-placement="top"] .tooltip-arrow {
    border-left: 0;
    border-top: 0;
    border-bottom-right-radius: 2px;
}

div[data-popper-placement="bottom"] .tooltip-arrow-wrapper {
    top: -5px;
}

div[data-popper-placement="bottom"] .tooltip-arrow{
    border-right: 0;
    border-bottom: 0;
    border-top-left-radius: 2px;
}

