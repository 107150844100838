@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@100;900&display=swap');;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap");
}

body {
  @apply bg-[#16191B] text-white antialiased;
  -webkit-tap-highlight-color: transparent;
}

.tab-active-border {
  text-shadow: -0.075px -0.075px 0 white, 0.075px -0.075px 0 white,
    -0.075px 0.075px 0 white, 0.075px 0.075px 0 white;
}

.tab-active-border::after {
  content: "";
  display: block;
  margin: 0.95rem auto 0 auto;
  width: 24px;
  border-radius: 20px;
  /* border-bottom: 2px solid #dbd557; */
  border-bottom: 2px solid transparent;
}

.tab-deactive-border::after {
  content: "";
  display: block;
  margin: 0.95rem auto 0 auto;
  width: 24px;
  border-radius: 20px;
  border-bottom: 2px solid transparent;
}

@layer components {
  .swiper-button-view {
    @apply !w-10 !h-10 rounded-full flex bg-[#373E43] bg-no-repeat bg-center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  }
}

@layer utilities {
  .outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .gradient-bg-gray {
    background-image: linear-gradient(180deg, #262C30 21.32%, rgba(38, 44, 48, 0.8) 136.03%);
  }
}

.swiper-button-prev {
  left: -1rem !important;
}

.swiper-button-prev::after {
  @apply hidden;
}

.swiper-button-next {
  right: -1rem !important;
}

.swiper-button-next::after {
  @apply hidden;
}

.channelBgGradient {
  background-image: linear-gradient(to bottom, #181b1e, #292a2c, #181b1e);
}

.button-solid-shadow {
  box-shadow: 4px 6px #49525a;
}

.button-solid-shadow-black {
  box-shadow: 4px 6px #16191b;
}

.button-solid-shadow-blue {
  box-shadow: 4px 6px #1c2575;
}

.btn-hover-bg::before {
  content: "";
  transform: scale(0.7) perspective(1px);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  opacity: 0;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.05);
}

.btn-hover-bg-light::before {
  background: rgba(0,0,0,0.05);
}

.btn-hover-bg.active::before,
.btn-hover-bg:hover::before {
  transform: scale(1.035) perspective(1px);
  opacity: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-slider {
  @apply inline-block h-14 md:h-[67px] lg:h-[86px] overflow-hidden align-middle;
  -webkit-mask-image: linear-gradient(
    transparent,
    white,
    white,
    white,
    white,
    white,
    white,
    transparent
  );
  mask-image: linear-gradient(
    transparent,
    white,
    white,
    white,
    white,
    white,
    white,
    transparent
  );
  mask-type: luminance;
  mask-mode: alpha;
}

.text-slider-words {
  @apply inline-block p-0 m-0;
  -webkit-animation-name: wordSlider;
  animation-name: wordSlider;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}

@-webkit-keyframes wordSlider {
  0%,
  10.28% {
    transform: translateY(0%);
  }
  14.28%,
  24.56% {
    transform: translateY(-12.5%);
  }
  28.56%,
  38.84% {
    transform: translateY(-25%);
  }
  42.84%,
  53.12% {
    transform: translateY(-37.5%);
  }
  57.12%,
  67.4% {
    transform: translateY(-50%);
  }
  71.4%,
  81.68% {
    transform: translateY(-62.5%);
  }
  85.68%,
  96% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(-87.5%);
  }
}

@keyframes wordSlider {
 0%,
 10.28%{
  transform: translateY(0%);
 }
 14.28%,
 24.56%{
  transform: translateY(-12.5%);
 }
 28.56%,
 38.84%{
  transform: translateY(-25%);
 }
 42.84%,
 53.12%{
  transform: translateY(-37.5%);
 }
 57.12%,
 67.4%{
  transform: translateY(-50%);
 }
 71.4%,
 81.68%{
  transform: translateY(-62.5%);
 }
 85.68%,
 96%{
  transform: translateY(-75%);
 }
 100%{
  transform: translateY(-87.5%);
 }
}

.get-started-button {
  transition-timing-function: cubic-bezier(0.45, 2.07, 1, 1);
}

.investor-card {
  transition-timing-function: cubic-bezier(0.3, 1.34, 1, 1);
  transition-duration: 0.18s;
}

.header-reveal-animation {
  transition-timing-function: cubic-bezier(0.19, 1, 0.61, 1.11);
  transition-duration: 0.6s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

[data-rmiz-wrap="visible"] {
  width: 100%;
  overflow: hidden;
}

.support-my-passion-btn-bg {
  background: linear-gradient(
    103.96deg,
    #5733e3 9.74%,
    #6e50e7 89.9%,
    #6e50e7 89.9%
  );
}

.custom-carousel .swiper-slide {
  width: 100% !important;
  overflow: hidden;
}

.selection-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 2px;
  width: 24px;
  border-radius: 50px;
  transform: scaleX(0);
  background: #dbd557;
  transform-origin: left center;
  transition: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.vjs-control-bar > button {
  @apply outline-none focus:outline-none;
}

.vjs-big-play-button {
  border-radius: 100% !important;
  width: 1.63332em !important;
  transform: translateX(40%) !important;
}

.notion-app,
.notion-header {
  @apply !bg-[#16191B];
}

button {
  @apply outline-none focus:outline-none;
}

.channel-list a:last-child > li {
  @apply mb-0;
}

.timeline-icon {
  @apply !w-1.5 !h-1.5 !overflow-hidden !rounded-full !bg-[#C4C4C4] opacity-30;
}

.timeline-icon-active {
  @apply !opacity-100;
}

.marquee {
  align-items: flex-start !important;
}

/* For Chrome */
.disable-scrollbars::-webkit-scrollbar {
  height: 100%;
  background: transparent; /* Chrome/Safari/Webkit */

  @apply w-0;
}

.disable-scrollbars::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

.disable-scrollbars:hover::-webkit-scrollbar-thumb {
  background-color: #22272b;
}

/* For Firefox */
.scrollbars {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
}

.scrollbars:hover {
  scrollbar-color: #22272b transparent;
}

#single-otp-input::placeholder {
  font-size: 3rem;
  letter-spacing: 0.2em;
  transform: translateY(0.25rem);
}

#single-otp-input::-webkit-input-placeholder {
  font-size: 3rem;
  letter-spacing: 0.2em;
  transform: translateY(0.25rem);
}

#single-otp-input::-moz-placeholder {
  font-size: 3rem;
  letter-spacing: 0.2em;
  transform: translateY(0.25rem);
}

.otp-btn-disabled {
  background: linear-gradient(
    134.63deg,
    rgba(77, 97, 116, 0.4) 10.4%,
    rgba(61, 73, 85, 0.4) 90.09%
  ) !important;
  cursor: not-allowed !important;
}

.otp-btn-gradient::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top left, #4d6174, #3d4955);
  z-index: -1;
  transition: opacity 0.15s linear;
  opacity: 0;
}

.otp-btn-gradient:hover::before {
  opacity: 1;
}

b {
  @apply font-bold;
}

.lesson-list-transition {
  transition: max-height 0.25s ease-out;
}

/* .lesson-list-active {
  max-height: auto;
}

.lesson-list-deactive {
  max-height: 88px;
} */

.form-checkbox {
  color: #605FE7;
}

.form-checkbox:checked {
  border: transparent;
}

.gradient-gray {
  background-image: linear-gradient(134.63deg, #4D6174 10.4%, #3D4955 90.09%);
}

.bg-dot-pattern{
  background-image: url("/assets/images/dot-bg-repeat-pattern.png");
  background-repeat: repeat;
}