.react-calendar {
  background: transparent !important;
  line-height: 28px !important;
  border: none !important;
  margin: 0 auto !important;
  width: auto !important;
}

.react-calendar__navigation {
  align-items: center;
  color: #a6a6a6;
}

/* goes to next year */
.react-calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar .react-calendar__navigation__label {
  order: -1;
  justify-content: flex-start;
}

.react-calendar .react-calendar__navigation__arrow {
  height: min-content !important;
  width: min-content !important;
  min-width: 0 !important;
  margin-left: 30px !important;
}

/* Changed style for disabled view change when clicked on month */
.react-calendar .react-calendar__navigation button:nth-child(3):disabled {
  background: transparent;
  opacity: 1;
  cursor: auto;
}

/* Navigation buttons */
.react-calendar button.react-calendar__navigation__arrow {
  font-size: 25px !important;
}
.react-calendar button.react-calendar__navigation__arrow,
.react-calendar button.react-calendar__navigation__arrow:active,
.react-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:active,
.react-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button:active,
.react-calendar button.react-calendar__navigation__arrow:focus,
.react-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:focus,
.react-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button:focus,
.react-calendar button.react-calendar__navigation__arrow:hover,
.react-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover,
.react-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover {
  background: transparent !important;
  color: #fff;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:disabled {
  background-color: transparent !important;
}

/* react-calendar__tile.react-calendar__month-view__days__day */

.react-calendar__month-view__weekdays {
  color: #a6a6a6;
  font-weight: 400;
}

.react-calendar .react-calendar__month-view__weekdays__weekday,
.react-calendar .react-calendar__month-view__weekdays__weekday:focus,
.react-calendar .react-calendar__month-view__weekdays__weekday:active,
.react-calendar .react-calendar__month-view__weekdays__weekday:hover {
  background: transparent;
}

.react-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

/* neighbouring month's dates */
.react-calendar
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0 !important;
  pointer-events: none !important;
}

/* weekend - dull by default */
.react-calendar
  .react-calendar__tile.react-calendar__month-view__days__day--weekend {
  color: #fff !important;
}

/* remove bgcolor for all day tiles */
.react-calendar .react-calendar__tile.react-calendar__month-view__days__day,
.react-calendar
  .react-calendar__tile.react-calendar__month-view__days__day:disabled,
.react-calendar
  .react-calendar__tile.react-calendar__month-view__days__day:disabled
  abbr {
  background: transparent !important;
}

/* button -> date / month tile */
.react-calendar button {
  border-radius: 1000px;
  padding: 0px;
  height: 3.6em !important;
  width: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar button abbr {
  /* padding: 1em; */
  width: 2.4em;
  height: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: hsl(206, 14%, 20%); */
  border: 1px solid #a6a6a6;
  border-radius: 1000px;
}

/* disabled button -> days which have been disabled by the creator */
.react-calendar button:disabled {
  background: transparent;
  opacity: 0.4;
  cursor: not-allowed;
}

.react-calendar button:disabled abbr {
  border-color: transparent;
}

.react-calendar button:hover {
  background: #222629 !important;
}

.react-calendar button:disabled:hover {
  background: transparent !important;
}

/* active date tile -> the tile user clicked */
.react-calendar .react-calendar__tile.react-calendar__tile--active abbr,
.react-calendar .react-calendar__tile.react-calendar__tile--active:focus abbr,
.react-calendar .react-calendar__tile.react-calendar__tile--active:hover abbr {
  background: #4d4ce1 !important;
  border-color: #4d4ce1;
}
