* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  font-size: 50px;
}

.layout {
  padding: 0 40px;
  position: relative;
}

.header {
  padding: 32px 0;
}

.avatar-div {
  /*margin-top: 72px;*/
}

.avatar {
  height: 100px;
  width: 100px;
  border-radius: 32px;
  object-fit: contain;
}

.main {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
  min-height: 56%;
}

.hero {
  margin-top: 40px;
}

.nav {
  float: right;
}

.nav a + a {
  margin-left: 20px;
}

.nav a + a {
  margin-left: 20px;
}

.button {
  display: inline-block;
  border-radius: 8px;
  padding: 16px 36px;
  outline: none;
  border: none;
  font-size: 16px;
  transition: 0.16s transform, background-color 10s ease-in;
}

.button:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #33dd9f 52%, #337fdd);
  cursor: pointer;
}

.primary-button {
  color: #fff;
  background: #33dd9f;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
}

.text-primary {
  color: #33dd9f;
}

.no-highlights {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.category {
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  border-radius: 20px;
  color: white;

  font-weight: 400;
  font-size: 13px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: inline-block;
  margin: 0 12px;
  padding: 7px 16px;
}

.border-color {
  background: linear-gradient(
    to right,
    rgba(51, 221, 159, 1),
    rgba(255, 247, 55, 1)
  );
  padding: 2px 2px;
  border-radius: 8px;
}

.color-btn {
  background: #0d0d0f;
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  height: 36px;
}

.color-btn span {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 20px;
  width: 132px;
}

.word-animation-container {
  position: relative;
  width: 200px;
  overflow: hidden;
  margin: 56px auto;
  border-radius: 40px;
  white-space: nowrap;
}

.channel-word-animation-container {
  position: relative;
  width: 150px;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 40px;
  white-space: nowrap;
  font-size: 20px;
}

.dummy-word {
  visibility: hidden;
}

.scribble {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 32px;
  height: 100%;
}

.hero-chat {
  width: 100%;
  max-width: 540px;
  margin: 52px auto 40px;
}

.bubble-row {
  width: 100%;
  margin: 32px 0;
  overflow: auto;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
}

.right-bubble {
  border-radius: 40px 40px 0 40px;
  float: right;
}

.bubble {
  padding: 12px;
  line-height: 36px;
}

.creator-chat {
  background: #242121;
}

.creator-chat-1 {
  height: 120px;
  width: 45%;
  min-width: 240px;
}

.creator-chat-2 {
  min-height: 60px;
  width: 50%;
  min-width: 240px;
}

.user-chat {
  min-height: 60px;
  background: #1b1919;
}

.user-chat-1 {
  width: 45%;
  min-width: 240px;
}

.user-chat-2 {
  width: 108px;
}

.left-bubble {
  border-radius: 40px 40px 40px 0;
}

.animate-word {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(100%);
  animation: 4.5s slide_left infinite forwards;
  width: 100%;
  text-align: center;
  display: inline-block;
  will-change: transform;
}

.animate-word:nth-of-type(1) {
  color: #fffa2a;
}

.animate-word:nth-of-type(2) {
  animation-delay: 1.5s;
  color: #83ffc6;
}

.animate-word:nth-of-type(3) {
  animation-delay: 3s;
  color: #2ef72e;
}

.social-links {
  margin-top: 32px;
}

.social-link {
  display: inline-block;
  opacity: 0.9;
}

.social-link:hover {
  opacity: 0.75;
}

.social-link img {
  width: 24px;
  margin: 0 8px;
}

.made-in-india {
  text-align: center;
  font-size: 12px;
}

.made-in-india span {
  display: inline-block;
  vertical-align: middle;
}

.made-in-india .text {
  opacity: 0.7;
}

.made-in-india svg {
  width: 16px;
  margin-right: 4px;
}

.tag {
  border-radius: 6px;
  background: #201e1e;
  padding: 4px 8px;
  font-size: 12px;
}

.tag span {
  display: inline-block;
  vertical-align: middle;
}

.tag-big {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
}

.tag-dot {
  display: inline-block;
  margin-right: 4px;
}

.tag-yellow {
  background: #f3ed34;
  color: #100e0e;
}

.header-logo img {
  display: inline-block;
  vertical-align: middle;
}

.copyright {
  opacity: 0.65;
  margin-top: 40px;
}

.company-info-section {
  margin: 40px 0 20px 0;
}

.company-info-block {
  display: inline-block;
  min-width: 200px;
}

.company-info-block .content {
  display: inline-block;
}

.company-info-block .content a {
  display: block !important;
  text-align: left;
}

.info-points {
  margin-top: 12px;
  font-size: 16px;
}

.info-points a {
  color: #fff;
  margin: 12px;
  min-width: 80px;
}

.info-points a:hover {
  opacity: 0.75;
}

.get-early-access-button {
  display: inline-block;
  text-decoration: none;
  background-color: #33dd9f;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  height: 48px;
  padding: 6px 32px;
  border-radius: 7px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.notion-page-icon-wrapper {
  justify-content: start !important;
}

.text-yellow.text-yellow {
  color: #f3ed34;
}

.center-placeholder::placeholder {
  text-align: center;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.video-js.vjs-youtube {
  margin-top: 1.25rem;
  height: 245px;
  border-radius: 10px;
  overflow: hidden;
}
.hide-vjs-spinner .vjs-loading-spinner {
  display: none !important;
}

.course.video-js.vjs-youtube,
.youtube-player.video-js.vjs-youtube {
  margin-top: 0;
  height: 100%;
}

.course .video-js,
.youtube-player .video-js {
  height: 100%;
}

.course#player,
.youtube-player#player {
  height: 100% !important;
}

.autocomplete-background:-webkit-autofill,
.autocomplete-background:-webkit-autofill:hover,
.autocomplete-background:-webkit-autofill:focus,
.autocomplete-background:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  -webkit-caret-color: #fff !important;
  caret-color: #fff !important;
}

@keyframes loading-bounce {
  0%,
  100% {
    transform: translateY(-100%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes loading-opacity {
  0%,
  100% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-loading-bounce {
  animation: loading-bounce 1s infinite;
}

.animate-loading-opacity {
  animation: loading-opacity 1s infinite;
}

.animation-delay-100 {
  animation-delay: 100ms;
}

.animation-delay-200 {
  animation-delay: 200ms;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-400 {
  animation-delay: 400ms;
}

.animation-delay-500 {
  animation-delay: 500ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

@media screen and (min-width: 768px) {
  .banner-clip {
    clip-path: polygon(
      0 0,
      4px 50%,
      0 100%,
      100% 100%,
      calc(100% - 4px) 50%,
      100% 0%
    );
  }
}


input.blue-slider {
  -webkit-appearance: none;
  appearance: none;
  background: #c6c6c6;
  cursor: pointer;
  border-radius: 999rem;
}

input.blue-slider:focus {
  outline: none;
}

input.blue-slider::-webkit-slider-runnable-track {
  border-radius: 999rem;
  height: 4px;
}

input.blue-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -6px;
  background-color: #5754f5;
  border-radius: 999rem;
  height: 1rem;
  width: 1rem;
}

input.blue-slider:focus::-webkit-slider-thumb {
  outline: none;
}

input.blue-slider::-moz-range-track {
  border-radius: 999rem;
  height: 4px;
}

input.blue-slider::-moz-range-thumb {
  background-color: #5754f5;
  border: none;
  border-radius: 999rem;
  height: 1rem;
  width: 1rem;
}

input.blue-slider:focus::-moz-range-thumb {
  outline: none;
}

.gradient-fade-white {
  background-color: transparent;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.85) -76.47%, #FFFFFF 100%);
}

.gradient-instagram {
  color: #d300c5;
  background-image: linear-gradient(45deg, #ffd600 0%, #ff7a00 20%, #ff0069 40%, #d300c5 60%, #7638FA 80%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.tools-carousel .carousel.carousel-slider {
  overflow: visible;
}

.center-carousel li.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

#zmmtg-root{
  background-color: #16191B !important;
}

.vjs-playback-rate .vjs-menu-item-text {
  /* 1em = 12px in this context */
  font-size: 0.9167em !important; 
}

.vjs-playback-rate .vjs-playback-rate-value {
  /* 1em = 10px in this context */
  font-size: 1.3em !important;
  line-height: 2.3 !important;
}

:root {
  color-scheme: light only;
}

.gradient-overlay::before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .4), rgba(0, 0, 0, .8));
  position: absolute;
  inset: 0;
}
.watermark {
  transform: translate(-50%, -50%);
}

input.green-slider {
  -webkit-appearance: none;
  appearance: none;
  background: #00000026;
  cursor: pointer;
  border-radius: 999rem;
  height: 6px;
}

input.green-slider:focus {
  outline: none;
}

input.green-slider::-webkit-slider-runnable-track {
  border-radius: 999rem;
  height: 6px;
  outline: none;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}

input.green-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -10px;
  background-color: #20B038;
  border-radius: 999rem;
  height: 1.5rem;
  width: 1.5rem;
}

input.green-slider:focus::-webkit-slider-thumb {
  outline: none;
}

input.green-slider::-moz-range-track {
  border-radius: 999rem;
  height: 6px;
  outline: none;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}

input.green-slider::-moz-range-thumb {
  appearance: none;
  background-color: #20B038;
  border: none;
  margin-top: -10px;
  border-radius: 999rem;
  height: 1.5rem;
  width: 1.5rem;
}

input.green-slider:focus::-moz-range-thumb {
  outline: none;
}

.strike-through::after {
  content: '';
  height: 1px;
  display: block;
  left:0;
  right:0;
  top: 50%;
  transform: translateY(-50%);
  background: #16191B;
  position: absolute;
  z-index: 1;
}

.bullet-rectangle {
  width: 40px;
  height: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 4px;
}

.bullet-rectangle::before {
  content: '';
  width: 36px;
  height: 2px;
  border-radius: 1px;
  background-color: #16191B;
  display: block;
  opacity: 0.12;
}

.bullet-rectangle-active::before {
  opacity: 0.7;
}

.reviews-swiper .swiper-wrapper {
  align-items: stretch;
  margin-bottom: 3.75rem;
}